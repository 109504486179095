<template>
  <div class="warp">
    <van-form @submit="onSubmit">
      <van-field
        clickable
        label-width="100"
        v-model="query.enterName"
        name="产废企业名称"
        label="产废企业名称"
        placeholder="请输入产废企业名称"
      />
      <van-field
        clickable
        label-width="100"
        v-model="query.creditCode"
        name="社会信用代码"
        label="社会信用代码"
        placeholder="请输入社会信用代码"
      />
      <van-field
        label-width="100"
        v-model="query.title"
        name="走访主题"
        label="走访主题"
        placeholder="请输入走访主题"
      />
      <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="tiemValue"
        label-width="100"
        label="走访时间"
        placeholder="点击选择时间"
        @click="showPicker1 = true"
      />
      <van-popup v-model="showPicker1" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="选择完整时间"
          @confirm="onConfirm1"
          @cancel="showPicker1 = false"
        />
      </van-popup>

      <van-field
        label-width="100"
        v-model="query.person"
        name="走访人姓名"
        label="走访人姓名"
        placeholder="请输入走访人姓名"
      />
      <van-field
        readonly
        label-width="100"
        clickable
        name="走访人所在企业"
        :value="query.personUnit"
        label="走访人所在企业"
        placeholder="点击选择走访人所在企业"
        @click="showPicker2 = true"
      />
      <van-popup v-model="showPicker2" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns2"
          @confirm="onConfirm2"
          @cancel="showPicker2 = false"
        />
      </van-popup>
      <van-field
        label-width="100"
        rows="2"
        autosize
        type="textarea"
        maxlength="50"
        v-model="query.locSign"
        name="签到地点"
        label="签到地点"
        placeholder="请输入签到地点"
      />
      <van-field
        label-width="100"
        rows="2"
        autosize
        type="textarea"
        maxlength="50"
        v-model="query.remark"
        name="备注"
        label="备注"
        placeholder="请输入备注"
      />
      <van-field name="imgUrl" label-width="70" label="上传图片">
        <template #input>
          <van-uploader @delete="deleteCf" v-model="imgUrl" :max-count="5" />
        </template>
      </van-field>
      <van-field name="attachsUrl" label-width="70" label="上传附件">
        <template #input>
          <van-uploader
            accept=".zip,.rar,.doc,.xls,.pdf,.png,.jpg,.jpeg"
            :max-size="100 * 1024 * 1024"
            @oversize="onOversize"
            @delete="deleteCf"
            v-model="attachsUrl"
            :max-count="5"
          />
        </template>
      </van-field>
      <div class="beizhu">
        <p>
          说明：系统会根据您输入的详细地址自动获取对应的经纬度，如果输入的地址不准确，获取经纬度会不准确
        </p>
      </div>
      <div class="btns">
        <van-button
          round
          block
          type="info"
          v-if="isShowbtn"
          native-type="submit"
          >添加</van-button
        >
      </div>
    </van-form>
    <div class="btn">
      <van-button
        round
        block
        loading
        type="info"
        v-if="!isShowbtn"
        loading-text="加载中..."
      ></van-button>
      <div style="height:10px;"></div>
      <van-button round block type="default" @click="goBackClick"
        >返回</van-button
      >
    </div>
  </div>
</template>

<script>
  import { visitRecordsave } from '@/api/enterprise.js';
  import { jsapiSignature } from '@/api/notice.js';
  import { dictType } from '@/api/notice.js';
  import { Dialog } from 'vant';
  import { Toast } from 'vant';
  export default {
    data() {
      return {
        amap_key: '3b7a88fa011cc25b116c2cd355292b1d',
        isShowbtn: true,
        showPicker1: false,
        currentDate: new Date(),
        query: {
          comId: undefined, // 企业id
          enterName: undefined, // 产废企业名称
          creditCode: undefined, // 产废企业统一社会信用代码
          title: undefined, // 走访主题
          date: undefined, // 走访时间 yyyy-MM-dd HH:mm:ss
          person: undefined, // 走访人姓名
          personUnit: undefined, // 走访人所在企业名称
          latitude: undefined,
          longitude: undefined,
          locSign: undefined, // 签到地点
          imgUrl: undefined, // 图片url，多个用','分隔
          remark: undefined,
          attachsUrl: undefined, // 附件信息url，多个用','分隔
        },
        showPicker2: false,
        tiemValue: undefined, // 走访时间
        imgUrl: undefined, // 图片url，多个用','分隔
        attachsUrl: undefined, // 附件信息url，多个用','分隔
        client: new OSS.Wrapper({
          region: 'oss-cn-hangzhou',
          accessKeyId: 'LTAI5tRypK9PNyfDKPcabDhD',
          accessKeySecret: 'ykKGkcSM0ewsUcW4rAUd9htNOJwKVp',
          bucket: 'hzjiaduomei-huishou',
        }),
        tempArr: [],
        urlArr: [],
        columns2: [],
      };
    },
    created() {
      this.dictType();
    },
    methods: {
      onConfirm2(value) {
        this.showPicker2 = false;
        this.query.personUnit = value.text;
      },
      dictType() {
        dictType('hs_visittor_unit').then((res) => {
          // 走访人企业
          if (res.data.code === 200) {
            this.columns2 = (res.data.data || []).map((item) => {
              return {
                id: item.dictLabel,
                text: item.dictLabel,
              };
            });
            this.jsapiSignature();
          }
        });
      },
      jsapiSignature() {
        // 自动获取地址
        let self = this;
        let hrefurl = sessionStorage.getItem('url');
        jsapiSignature({ url: hrefurl }).then((res) => {
          wx.config({
            debug: false, // 开启调试模式,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.checkJsApi({
            jsApiList: ['getLocation'],
            success: (res) => {
              if (res.checkResult.getLocation == false) {
                alert(
                  '你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！'
                );
                return;
              }
            },
            fail(res) {
              const message = 'checkJsApi fail=' + JSON.stringify(res);
              Toast(message);
            },
          });
          wx.ready(() => {
            wx.getLocation({
              type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: (res) => {
                self.query.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                self.query.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                let location = self.query.longitude + ',' + self.query.latitude;
                self.locationToAddress(location);
              },
              fail(res) {
                Toast('定位获取失败，请自行输入err:' + JSON.stringify(res));
                console.info('ready getLocation fail=' + JSON.stringify(res));
              },
            });
          });
          wx.error(function(res) {
            Toast('wx.error: ' + JSON.stringify(res));
            console.log(res);
          });
        });
      },
      locationToAddress(location) {
        // 经纬度转详细地址
        this.$axios({
          method: 'get',
          url: 'https://restapi.amap.com/v3/geocode/regeo?parameters',
          params: {
            key: this.amap_key,
            location: location,
          },
        }).then((res) => {
          this.query.locSign = res.data.regeocode.formatted_address;
        });
      },
      onOversize(file) {
        console.log(file);
        Toast('文件大小不能超过 100M');
      },
      deleteCf() {
        Dialog.confirm({
          title: '关闭图片文件',
          message: '确认关闭图片文件！！！,图片文件关闭后需重新选择',
        })
          .then(() => {})
          .catch(() => {});
      },
      onSubmit(file) {
        // 回访记录
        this.isShowbtn = false;
        const arr = [];
        if (this.imgUrl && this.attachsUrl) {
          const filearr = file.imgUrl.concat(file.attachsUrl);
          filearr.forEach((item) => {
            arr.push(item.file);
          });
        } else if (this.imgUrl) {
          file.imgUrl.forEach((item) => {
            arr.push(item.file);
          });
        } else if (this.attachsUrl) {
          file.attachsUrl.forEach((item) => {
            arr.push(item.file);
          });
        }
        this.tempArr = arr;
        if (this.tempArr.length) {
          for (let i = 0; i < this.tempArr.length; i++) {
            this.uploadDocuments(this.tempArr[i]);
          }
        } else {
          this.isShowbtn = true;
          Toast('请上传图片');
        }
      },
      uploadDocuments(tempArr) {
        // 上传证件
        const Name = tempArr.name; // 这是file里的name
        const suffix = Name.substr(Name.indexOf('.')); // 文件.后的后缀
        // 文件名:图片名字加上时间戳和9999以内随机数，防止重名
        const filename = `hfjl/${this.conversionTime(
          new Date().getTime()
        )}/${new Date().getTime()}${Math.floor(
          Math.random() * 10000
        )}${suffix}`;
        this.client
          .multipartUpload(filename, tempArr)
          .then((res) => {
            // 上传
            console.log('上传成功：', res);
            this.urlArr.push(res.name);
            if (this.tempArr.length === this.urlArr.length) {
              this.query.attachsUrl = this.urlArr.join(',');
              visitRecordsave(this.query).then((res) => {
                if (res.data.code === 200) {
                  Toast('保存成功');
                  this.$router.go(-1);
                } else {
                  this.urlArr = [];
                  this.isShowbtn = true;
                }
              });
            }
          })
          .catch((err) => {
            this.isShowbtn = true;
            console.log('上传失败：', err);
          });
      },
      onConfirm1(time) {
        this.tiemValue = this.conversionTime(time.getTime() / 1000);
        this.query.date = this.conversionTime(time.getTime() / 1000);
        this.showPicker1 = false;
      },
      goBackClick() {
        this.$router.back();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .warp {
    background-color: #fff;
    .beizhu {
      padding: 15px;
      font-size: 14px;
      p {
        line-height: 25px;
      }
    }
    .btns {
      padding: 7.5px 30px 0 30px;
    }
    .btn {
      padding: 0 30px 7.5px;
    }
  }
  ::v-deep .van-button--round {
    border-radius: 3px;
  }
  ::v-deep .van-button--info {
    background-color: #0087f0;
    border: 1px solid #0087f0;
  }
</style>
