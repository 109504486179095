import { request } from '../network/request.js'

export function companyList(query) {// 企业管理 列表
  return request({
    url: '/wechat/manager/company/list',
    method: 'get',
    params: query,
  })
}

export function companyreview(data) {// 企业管理 审核
  return request({
    url: '/wechat/manager/company/review',
    method: 'post',
    data: data,
  })
}

export function companysave(data) {// 企业管理 保存
  return request({
    url: '/wechat/manager/company/save',
    method: 'put',
    data: data,
  })
}

export function companyid(id) {// 企业管理 详情
  return request({
    url: '/wechat/manager/company/' + id,
    method: 'get'
  })
}

export function recharge(query) {// 企业管理 充值
  return request({
    url: '/wechat/manager/company/recharge',
    method: 'post',
    params: query,
  })
}

export function visitRecordsave(data) {// 走访记录填写
  return request({
    url: '/wechat/manager/visitRecord/save',
    method: 'post',
    data: data,
  })
}
