import axios from 'axios'
import router from '../router'
import { Toast } from 'vant'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

export function request(config, res){
	const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
		timeout: 10000
  })
  // 首先对拦截器的请求进行设置，并在方法中return config，此处为固定格式
  instance.interceptors.request.use(config => {
    return config
  })
  // 响应拦截器
  instance.interceptors.response.use(res => {
    const code = res.data.code
    if (code !== 200) {
      Toast(res.data.msg)
    }
    if (code === 403) {
      router.push({ path: '/Errorpage' })
    }
    return res
  },
  error => {
    console.log(error.response)
    if (error.response.status === 403) {
      router.push({ path: '/Errorpage' })
    }
    return Promise.reject(error);// 返回接口返回的错误信息
  }
  )
  return instance(config, res)
}
