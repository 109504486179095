import { request } from '../network/request.js'

export function login(query) {// 用户登录
  return request({
    url: '/wechat/login',
    method: 'get',
    params: query,
  })
}

export function getInfo() {// 获取用户信息
  return request({
    url: '/wechat/getInfo',
    method: 'get',
  })
}

export function jsapiSignature(query) {// jsapi信息
  return request({
    url: '/wechat/jsapi/signature',
    method: 'get',
    params: query,
  })
}

export function categoryId(id) {// 分类信息
  return request({
    url: '/wechat/recycle/category/' + id,
    method: 'get',
  })
}

export function addresslist() {// 地址 列表
  return request({
    url: '/wechat/user/address/list',
    method: 'get',
  })
}

export function address(data) {// 地址 保存
  return request({
    url: '/wechat/user/address',
    method: 'put',
    data: data
  })
}

export function getDivisionList(data) {// 区域代码 下级列表
  return request({
    url: '/wechat/division/getDivisionList',
    method: 'post',
    data: data
  })
}

export function addressEdit(id) {// 地址 详情
  return request({
    url: '/wechat/user/address/' + id,
    method: 'get',
  })
}

export function addressdel(id) {// 地址 删除
  return request({
    url: '/wechat/user/address/' + id,
    method: 'delete',
  })
}

export function runnerregister(data) {// 申请
  return request({
    url: '/wechat/runner/register',
    method: 'post',
    data: data
  })
}

export function register(data) {// 注册
  return request({
    url: '/wechat/company/register',
    method: 'post',
    data: data
  })
}

export function jsapisignature(query) {// jsapi信息
  return request({
    url: '/wechat/jsapi/signature',
    method: 'get',
    params: query,
  })
}

export function storeList() {// 贮藏点 列表
  return request({
    url: '/wechat/company/store/list',
    method: 'get',
  })
}

export function companyInfo() {// 企业基本资料 查看
  return request({
    url: '/wechat/company/info',
    method: 'get',
  })
}

export function editcompanyInfo(data) {// 企业基本资料 编辑
  return request({
    url: '/wechat/company/info',
    method: 'put',
    data: data
  })
}

export function orderplace(data) {// 订单 下单
  return request({
    url: '/wechat/company/order/place',
    method: 'post',
    data: data
  })
}

export function userList () { // 员工绑定 列表
  return request({
    url: '/wechat/company/user/list',
    method: 'get',
  })
}

export function companyqrCode () { // 二维码 获取
  return request({
    url: '/wechat/company/qrCode',
    method: 'get',
  })
}

export function dictType (dictType) { // 字典信息
  return request({
    url: '/wechat/dict/' + dictType,
    method: 'get',
  })
}

export function getLocation (runnerId) { // 清运员地理位置 获取
  return request({
    url: '/wechat/runner/getLocation/' + runnerId,
    method: 'get',
  })
}

export function userband (id) { // 员工绑定 绑定/同意
  return request({
    url: '/wechat/company/user/band/' + id,
    method: 'post',
  })
}

export function deluserband (id) { // 员工绑定 解绑/拒绝
  return request({
    url: '/wechat/company/user/band/' + id,
    method: 'delete',
  })
}

export function useradd (data) { // 员工绑定 填写员工信息
  return request({
    url: '/wechat/company/user/add',
    method: 'post',
    data: data
  })
}

export function noticelist (query) { // 政策 列表
  return request({
    url: '/wechat/notice/list',
    method: 'get',
    params: query
  })
}

export function noticeid (id) { // 政策 详情
  return request({
    url: '/wechat/notice/' + id,
    method: 'get',
  })
}

export function companyInfoqym (query) { // 企业码内容展示
  return request({
    url: '/wechat/common/companyInfo',
    method: 'get',
    params: query
  })
}

export function visitRecordcheck () { // 判断用户是否为业务员
  return request({
    url: '/wechat/manager/visitRecord/check',
    method: 'post',
  })
}

export function companyInfouscc (uscc) { // 走访记录 获取企业信息
  return request({
    url: '/wechat/manager/visitRecord/companyInfo/' + uscc,
    method: 'get',
  })
}
